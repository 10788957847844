/* eslint-disable no-param-reassign */
import { Table } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';

import { defaultPageSize } from 'constants/styles';
import { TablePaginationConfig } from 'antd/lib/table';
import { translate } from 'config/i18n';
import { useState } from 'react';
import { Container } from './styles';

export interface IDataDableColumns {
  title: string;
  dataIndex: string;
  sorter?: boolean;
}

interface IDataTableProps {
  columns: ColumnsType<IDataDableColumns>;
  dataSource: any;
  total: number | undefined;
  loading?: boolean;
  onChange: (page: number | undefined, pageSize: number | undefined) => void;
}

export default function DataTable({
  columns,
  dataSource,
  total,
  loading,
  onChange,
  ...rest
}: IDataTableProps): JSX.Element {
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const fullColumns: ColumnsType<IDataDableColumns> = [
    ...columns.map((c: ColumnType<IDataDableColumns>) => {
      c.align = c.align ? c.align : 'left';
      const columnName = c.dataIndex as string;
      c.sorter =
        c.sorter === false
          ? false
          : (a: any, b: any) =>
              a[columnName].toString().localeCompare(b[columnName]);
      c.showSorterTooltip = false;
      return { ...c };
    }),
    {
      title: translate('general.actions'),
      dataIndex: 'actions',
      align: 'center',
    },
  ];

  function handleTableChange(pagination: TablePaginationConfig) {
    onChange(pagination.current, pagination.pageSize);
  }

  function onShowSizeChange(_: any, newPageSize: number) {
    setPageSize(newPageSize);
  }

  return (
    <Container>
      <Table
        dataSource={dataSource}
        columns={fullColumns}
        bordered={false}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          pageSize,
          total,
          showSizeChanger: true,
          onShowSizeChange,
        }}
        {...rest}
      />
    </Container>
  );
}

DataTable.defaultProps = {
  loading: false,
};
