import styled from 'styled-components';

export const Container = styled.div`
  a.view-link {
    margin-right: 5px;
    img {
      height: 23px;
    }
  }

  a.edit-link {
    img {
      height: 20px;
    }
  }

  a.delete-link {
    img {
      height: 20px;
    }
  }
`;
