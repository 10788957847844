import styled from 'styled-components';
import { Input as AntdInput, Select } from 'antd';
import NumberFormat from 'react-number-format';
import { Card as CD } from '../styles';

export const Card = styled(CD)`
  position: relative;

  span.value {
    color: #fe6a21;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 13px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  button {
    position: absolute;
    right: 10px;
    top: 20px;

    img {
      width: 20px;
    }
  }
`;

export const Input = styled(AntdInput)`
  margin-top: 5px;
  height: 20px;
  font-weight: 600;
`;

export const PriceField = styled(NumberFormat)`
  margin-top: 5px;
  height: 20px;
  font-weight: 600;
`;

export const CustomSelect = styled(Select)`
  width: 100%;
`;

export const SwitchContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  margin-top: -10px;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #b9b9b9;
    margin-left: 15px;
  }

  & + & {
    margin-top: 10px;
  }
`;
