import styled from 'styled-components';

import { Menu as DefaultMenu } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const LeftOutlinedIcon = styled(LeftOutlined)`
  color: white;
`;

export const RightOutlinedIcon = styled(RightOutlined)`
  color: white;
`;

export const Container = styled.aside`
  flex-basis: 250px;
  width: 250px;
  min-height: 100vh;
  background-color: white;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 5;
  border-right: 2px solid #ececec;

  > div {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-left: 32px;
  }

  &.closed {
    flex-basis: 100px;
    width: 100px;
  }
`;

export const Logo = styled.img`
  height: 80px;
  margin-top: 1.5rem;
  transition: all 0.3s ease-in-out;
  width: 140px;

  &.closed {
    height: 50px;
    margin-bottom: 30px;
  }
`;

export const Menu = styled(DefaultMenu)`
  background: none;
  border-right: 0;
  margin-top: 2.5rem;
  overflow: auto;

  &.closed .ant-menu-item {
    width: fit-content;
    padding-right: 0;
    border-radius: @border-radius-base;

    span {
      display: none;
    }
  }
`;

export const MenuItem = styled(DefaultMenu.Item)`
  font-weight: 600;
  font-size: 19px;
  color: @heading-color;
  transition: border-radius 300ms ease-in-out;

  &.ant-menu-item {
    padding-left: 0.5rem;
  }

  &:hover {
    color: @heading-color;
  }

  &.ant-menu-item-selected {
    color: black;
    background: white !important;

    img {
      filter: ~'invert(58%) sepia(41%) saturate(6154%) hue-rotate(344deg) brightness(98%) contrast(104%)';
    }
  }

  &.ant-menu-item-selected::after {
    content: '';
    width: 3px;
    background: #fe6321;
    transform: inherit;
    opacity: 1;
  }

  img {
    height: 1.6rem;
    margin-bottom: 3px;
    margin-right: 0.5rem;
    fill: red !important;
    svg {
      fill: red;
    }
  }
`;

export const ToggleButton = styled.button.attrs({ type: 'button' })`
  width: 2.2rem;
  height: 2.2rem;
  background: #fe6321;
  border-radius: 50%;
  border: 0;
  top: 0;
  position: absolute;
  right: -1.25rem;
  z-index: 2;
  top: 5rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const EstablishmentMenu = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 45px;
  background: #f8f8f8 !important;
  border-top: 2px solid #ececec !important;
  justify-content: center;
  padding: 20px;
  margin-left: -32px;
  width: 248px;
  transition: all 0.3s ease-in-out;

  &.closed span.company-name {
    display: none;
  }

  &.closed {
    width: 98px;
  }

  div.placeholder {
    background: #f46323;
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    span {
      color: white;
      font-weight: bold;
    }

    > div {
      width: 45px;
      height: 45px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
    }
  }

  span {
    font-weight: 600;
    font-size: 18px;
    color: #535353;
  }
`;
