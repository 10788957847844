import { memo, useState, useEffect, useCallback } from 'react';
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons';

import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import api from 'services/api';

import { EstablishmentState } from 'interfaces/establishment';
import { notification, Menu } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { ProductCategory, ProductCategoryIcon } from 'interfaces/product';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import ConfirmModal from 'components/ConfirmModal';
import { translate } from 'config/i18n';

import { usePermissions } from 'hooks/usePermissions';
import { Roles } from 'constants/roles';
import FormModal from './FormModal';

import {
  Header,
  Button,
  Content,
  ImagePlaceholder,
  ImageContent,
  Dropdown,
  MenuItem,
} from './styles';

function ProductCategories() {
  const { has } = usePermissions();
  const [productCategories, setProductCategories] = useState<Array<any>>([]);
  const [rawProducts, setRawProducts] = useState<Array<ProductCategory>>([]);
  const [loading, setLoading] = useState(true);
  const [
    selectedProductCategory,
    setSelectedProductCategory,
  ] = useState<ProductCategory | null>(null);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  const columns: any = [
    {
      title: translate('product.image'),
      dataIndex: 'image',
      sorter: false,
    },
    {
      title: translate('product.name'),
      dataIndex: 'name',
    },
  ];

  function getProductImage(image: ProductCategoryIcon | undefined) {
    return image ? (
      <ImageContent>
        <img
          src={image.icon.location}
          alt={translate('product.product_image')}
        />
      </ImageContent>
    ) : (
      <ImagePlaceholder>
        <img
          src={ImagePlaceholderIcon}
          alt={translate('product.product_image')}
        />
      </ImagePlaceholder>
    );
  }

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${
            currentEstablishment?.id
          }/product-categories?page=${page || 1}&pageSize=${
            pageSize || defaultPageSize
          }`,
        );

        setPaginationTotal(response.headers['x-total'] || 0);

        setRawProducts(response.data.data);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  const confirmDeleteCategory = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(
        `trade/establishment-product-categories/${selectedProductCategory?.id}`,
      );
      handleChangeTable();
      setConfirmModalVisible(false);
      notification.success({
        message: translate('general.success'),
        description: translate('general_messages.was_removed', {
          context: 'product.category',
          gender: 'A',
        }),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [selectedProductCategory, handleChangeTable]);

  const handleDeleteCategory = useCallback(
    (productCategory: ProductCategory) => {
      setSelectedProductCategory(productCategory);
      setConfirmModalVisible(true);
    },
    [],
  );

  const DropdownMenu = useCallback(
    productCategory => (
      <Menu>
        {has([], [Roles.ADMIN, Roles.OPERATIONAL_MANAGER]) && (
          <MenuItem
            onClick={() => {
              setSelectedProductCategory(productCategory);
              setFormModalVisible(true);
            }}
          >
            {translate('general.edit')}
          </MenuItem>
        )}

        {has([], [Roles.ADMIN, Roles.OPERATIONAL_MANAGER]) && (
          <MenuItem onClick={() => handleDeleteCategory(productCategory)}>
            {translate('product.delete_category')}
          </MenuItem>
        )}
      </Menu>
    ),
    [handleDeleteCategory, has],
  );

  useEffect(() => {
    async function getProductsFromServer() {
      handleChangeTable();
    }

    getProductsFromServer();
  }, [currentEstablishment, handleChangeTable]);

  useEffect(() => {
    setProductCategories(
      rawProducts.map((x: ProductCategory) => ({
        ...x,
        image: getProductImage(x.categoryIcon),
        name: x.name,
        actions: (
          <>
            <Dropdown
              trigger={['click']}
              overlay={() => DropdownMenu(x)}
              placement="bottomLeft"
            >
              <EllipsisOutlined className="actions-icon" />
            </Dropdown>
          </>
        ),
      })),
    );
  }, [rawProducts, handleDeleteCategory, DropdownMenu]);

  function handleNewCategory() {
    setSelectedProductCategory(null);
    setFormModalVisible(true);
  }

  return (
    <Page
      displayDrawer
      title={translate('product.plural_title')}
      tabs={[
        {
          label: translate('product.plural_title'),
          route: '/products',
        },
        {
          label: translate('product.categories'),
          route: '/products/product-categories',
        },
      ]}
      SubHeader={
        <Header>
          <Button
            onClick={handleNewCategory}
            htmlType="button"
            type="primary"
            size="middle"
          >
            <PlusOutlined /> {translate('product.add_category')}
          </Button>
        </Header>
      }
    >
      <Content>
        <DataTable
          total={paginationTotal}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={productCategories}
        />
      </Content>

      <FormModal
        productCategory={selectedProductCategory as ProductCategory}
        establishmentId={currentEstablishment!.id}
        visible={formModalVisible}
        setVisible={setFormModalVisible}
        callBack={handleChangeTable}
      />

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('product.remove_confirmation', {
          productName: selectedProductCategory?.name,
        })}
        confirmCallback={confirmDeleteCategory}
      />
    </Page>
  );
}

export default memo(ProductCategories);
