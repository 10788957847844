import styled from 'styled-components';
import { Button as btn, Modal as antdModal, Typography } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    margin: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    padding-bottom: 15px;

    > a {
      background: #f6f6f6;
      color: #535353;
      padding: 3px 20px;
    }
  }

  div.modal-header {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 25px;
    color: #5d5e5d;

    div.placeholder {
      background: #f46323;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      span {
        color: white;
        font-weight: bold;
      }

      > div {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
  }
`;

export const ListItem = styled.ul`
  list-style: none;
  padding: 0;

  li > button {
    height: 80px;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    > span {
      font-size: 20px;
      font-weight: bold;
      color: #5d5e5d;
    }

    div.placeholder {
      background: #ccc;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;

      span {
        color: white;
        font-weight: bold;
      }

      > div {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
  }
`;

export const Button = styled(btn)`
  max-width: 240px;
  margin: 0 auto;
`;

export const HighlightText = styled(Typography.Text)`
  color: #fe6321;
  font-size: 18px;
`;
