import { memo, useState, useEffect, useCallback } from 'react';
import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';

import Page from 'components/Page';

import api from 'services/api';

import { EstablishmentState } from 'interfaces/establishment';
import { Menu, notification, Switch } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { formatPhone } from 'helpers/stringHelper';
import { Employee } from 'interfaces/employee';
import { establishmentRole } from 'helpers/mapper';
import { Role } from 'interfaces/role';
import ConfirmModal from 'components/ConfirmModal';
import { translate } from 'config/i18n';
import { IDataDableColumns } from 'components/DataTable';
import { EllipsisOutlined } from '@ant-design/icons';
import {
  Header,
  Button,
  Content,
  ImagePlaceholder,
  DataTable,
  SwitchContainer,
  Dropdown,
  MenuItem,
} from './styles';
import InviteModal from '../InviteModal';
import EditRoleModal from '../EditRoleModal';
import EditCodeModal from '../EditCodeModal';

function Employees() {
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [rawEmployees, setRawEmployees] = useState<Array<Employee>>([]);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [editRoleModalVisible, setEditRoleModalVisible] = useState(false);
  const [editCodeModalVisible, setEditCodeModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmModalStatusVisible, setConfirmModalStatusVisible] = useState(
    false,
  );
  const [editingEmployee, setEditingEmployee] = useState<Employee | null>(null);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [employeeToUnlink, setEmployeeToUnlink] = useState<Employee | null>(
    null,
  );
  const [
    employeeToChangeStatus,
    setEmployeeToChangeStatus,
  ] = useState<Employee | null>(null);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  const columns: IDataDableColumns[] = [
    {
      title: translate('employee.image'),
      dataIndex: 'avatar',
      sorter: false,
    },
    {
      title: translate('employee.name'),
      dataIndex: 'name',
    },
    {
      title: translate('employee.phone'),
      dataIndex: 'phone',
    },
    {
      title: translate('employee.email'),
      dataIndex: 'email',
    },
    {
      title: translate('employee.role'),
      dataIndex: 'roles',
    },
    {
      title: translate('employee.status'),
      dataIndex: 'status',
      sorter: false,
    },
  ];

  function getEmployeeAvatar() {
    return (
      <ImagePlaceholder>
        <img src={ImagePlaceholderIcon} alt={translate('general.avatar')} />
      </ImagePlaceholder>
    );
  }

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${currentEstablishment?.id}/employees?page=${
            page || 1
          }&pageSize=${pageSize || defaultPageSize}`,
        );

        setTotal(response.headers['x-total'] || 0);

        setRawEmployees(response.data.data);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  const handleUnlinkEmployee = useCallback((employee: Employee) => {
    setEmployeeToUnlink(employee);
    setConfirmModalVisible(true);
  }, []);

  const handleEditRoleEmployee = useCallback((employee: Employee) => {
    setEditingEmployee(employee);
    setEditRoleModalVisible(true);
  }, []);

  const handleEditCodeEmployee = useCallback((employee: Employee) => {
    setEditingEmployee(employee);
    setEditCodeModalVisible(true);
  }, []);

  const confirmUnlinkEmployee = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`trade/employees/${employeeToUnlink?.id}`);
      handleChangeTable();
      setConfirmModalVisible(false);
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    }
  }, [employeeToUnlink, handleChangeTable]);

  useEffect(() => {
    async function getEmployeesFromServer() {
      handleChangeTable();
    }

    getEmployeesFromServer();
  }, [currentEstablishment, handleChangeTable]);

  const handleActivateDeactivateEmployee = useCallback(async () => {
    try {
      setLoading(true);
      await api.post(
        `trade/employees/${employeeToChangeStatus?.id}/${
          employeeToChangeStatus?.disabledAt ? 'activate' : 'deactivate'
        }`,
      );
      handleChangeTable();
      notification.success({
        message: translate('general.success'),
        description: translate('employyyee.was_enabled_disabled', {
          action: employeeToChangeStatus?.disabledAt
            ? 'general.enabled'
            : 'general.disabled',
        }),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setConfirmModalStatusVisible(false);
    }
  }, [handleChangeTable, employeeToChangeStatus]);

  const DropdownMenu = useCallback(
    product => (
      <Menu>
        <MenuItem onClick={() => handleEditRoleEmployee(product)}>
          {translate('employee.edit')}
        </MenuItem>

        <MenuItem onClick={() => handleUnlinkEmployee(product)}>
          {translate('employee.delete')}
        </MenuItem>

        <MenuItem onClick={() => handleEditCodeEmployee(product)}>
          {translate('employee.change_operation_code')}
        </MenuItem>
      </Menu>
    ),
    [handleEditCodeEmployee, handleEditRoleEmployee, handleUnlinkEmployee],
  );

  useEffect(() => {
    setEmployees(
      rawEmployees.map((x: Employee) => ({
        ...x,
        avatar: getEmployeeAvatar(),
        name: x.user.name,
        phone: formatPhone(x.user.phone),
        email: x.user.email,
        roles: x.roles.map(role => establishmentRole[role.name]).join('\n'),
        status: (
          <SwitchContainer>
            <Switch
              checked={!x.disabledAt}
              onChange={() => {
                setConfirmModalStatusVisible(true);
                setEmployeeToChangeStatus(x);
              }}
            />
          </SwitchContainer>
        ),
        actions: (
          <>
            <Dropdown
              trigger={['click']}
              overlay={() => DropdownMenu(x)}
              placement="bottomLeft"
            >
              <EllipsisOutlined className="actions-icon" />
            </Dropdown>
          </>
        ),
      })),
    );
  }, [rawEmployees, handleUnlinkEmployee, DropdownMenu]);

  function handleInviteModal() {
    setInviteModalVisible(true);
  }

  return (
    <Page
      displayDrawer
      title={translate('employee.plural_title')}
      tabs={[
        {
          label: translate('employee.plural_title'),
          route: '/employees',
        },
        {
          label: translate('invite.plural_title'),
          route: '/employees/invites',
        },
      ]}
      SubHeader={
        <Header>
          <Button
            onClick={handleInviteModal}
            htmlType="button"
            type="primary"
            size="middle"
          >
            {translate('employee.invite')}
          </Button>
        </Header>
      }
    >
      <Content>
        <DataTable
          total={total}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={employees}
        />
      </Content>

      <InviteModal
        visible={inviteModalVisible}
        setVisible={setInviteModalVisible}
      />

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('employee.remove_confirmation', {
          employeeName: employeeToUnlink?.user.name,
        })}
        confirmCallback={confirmUnlinkEmployee}
      />

      <ConfirmModal
        loading={loading}
        visible={confirmModalStatusVisible}
        setVisible={setConfirmModalStatusVisible}
        confirmText={translate('employee.status_change_confirmation', {
          action: employeeToChangeStatus?.disabledAt ? 'ativar' : 'desativar',
          employeeName: employeeToChangeStatus?.user.name,
        })}
        confirmCallback={handleActivateDeactivateEmployee}
      />

      {editingEmployee && (
        <>
          <EditRoleModal
            employee={editingEmployee}
            visible={editRoleModalVisible}
            setVisible={setEditRoleModalVisible}
            callback={(roles: Array<Role>) => {
              setRawEmployees(
                rawEmployees.map(x =>
                  x.id === editingEmployee?.id ? { ...x, roles } : x,
                ),
              );
            }}
          />
          <EditCodeModal
            employee={editingEmployee}
            visible={editCodeModalVisible}
            setVisible={setEditCodeModalVisible}
          />
        </>
      )}
    </Page>
  );
}

export default memo(Employees);
