export default {
  translations: {
    dashboard: {
      title: 'Dashboard',
      sales: 'Sales',
      employees: 'Employees',
      financial_panel: 'Financial panel',
      employee_panel: 'Employee panel',
      granularity: 'Granularity',
      final_date: 'Final date',
      initial_date: 'Initial date',
      invalid_initial_date: 'The initial date provided is not a valid date.',
      invalid_final_date: 'The final date provided is not a valid date.',
    },
    profile: {
      title: 'Profile',
      avatar: 'Avatar',
      my_profile: 'My profile',
      linked_establishments: 'Linked establishments',
      remove_account_confirmation:
        'Are you sure you want to remove your account?',
      your_account_was_removed: 'Your account has been removed.',
      avatar_was_removed: 'The avatar has been removed.',
      avatar_was_updated: 'The avatar has been updated.',
      email_icon: 'E-mail icon',
      phone_icon: 'Phone icon',
      registration_data_updating: 'Update of registration data',
      name: 'Name',
      document: 'Document',
      email: 'Email',
      phone: 'Phone',
      new_email: 'New email',
      new_phone: 'New phone',
      upload_indicator: 'Upload indicator',
      current_password: 'Current password',
      new_password: 'New password',
      change_password: 'Change password',
      add_password: 'Add password',
      type_new_password: 'Type a new password to your account',
      password_updated_succeed: 'Your password has been changed',
      password_updated_failed: 'There was an error changing the password',
      code_confirm_failed: 'There was an error with the code confirmation',
      code_resend_failed: 'An error occurred while resending the code',
      code_resend_succeed: 'The code has been successfully resubmitted',
      code_send_failed: 'An error occurred while sending the code',
      code_send_succeed: 'The code has been successfully submitted',
      email_updated_succeed: 'Your email has been changed',
      email_updated_failed: 'There was an error changing the email',
      phone_updated_succeed: 'Your phone has been changed',
      phone_updated_failed: 'There was an error changing the phone',
      email_editing: 'Edição de email',
      phone_editing: 'Edição de telefone',
      type_new_email: 'Digite o novo email que deseja adicionar',
      type_new_phone: 'Digite o novo telefone que deseja adicionar',
      type_code_sent_to_field: 'Type the code sent to {{field}} for validation',
      field_confirmation: '$t({{field}}) confirmation',
      no_relationship: 'You are not yet linked to any establishment.',
    },
    operation: {
      title: 'Operation',
      plural_title: 'Operations',
      open: 'Open operation',
      close: 'Close operation',
      opening: 'Opening',
      closure: 'Closure',
      form_title: 'Title',
      was_opened: 'Operation successfully opened',
      was_closed: 'Operation successfully closed',
      establishment_options: 'Establishment options',
    },
    campaign: {
      title: 'Campaign',
      plural_title: 'Campaigns',
      validDate: 'Valid date',
      bonus: 'Bonus',
      limitMinValue: 'Minimum',
      limitMaxValue: 'Maximum',
      title_field: 'Title',
      situation: 'Situation',
      fixedPrice: 'Fixed value',
      credit: 'Credit',
      product: 'Product',
      ticket: 'Ticket',
      category: 'Category',
      publication: 'Publication',
      status: 'Status',
      activated: 'Activated',
      create: 'Create campaign',
      was_saved: 'Campaign has been saved',
      delete: 'Remove campaign',
      edit: 'Edit campaign',
      remove_confirmation:
        'Are you sure you want to remove the campaign {{campaignName}}?',
      endAt: 'Campaign start',
      startAt: 'Campaing end',
      start_end: 'Start/End',
      published: 'Published',
      limited: 'Limited',
      disabled: 'Disabled',
      duplicate: 'Duplicate',
      extend: 'Extend',
    },
    product: {
      title: 'Product',
      plural_title: 'Products',
      create: 'Insert product',
      delete: 'Remove product',
      delete_category: 'Delete category',
      edit: 'Edit product',
      product_image: 'Product image',
      remove_confirmation:
        'Are you sure you want to remove the product {{productName}}?',

      // FORM FIELDS
      image: 'Image',
      name: 'Name',
      icon: 'Icon',
      category: 'Category',
      delivery_workflow: 'Workflow',
      categories: 'Categories',
      favorite: 'Favorite',
      status: 'Status',
      price: 'Price',
      description: 'Description',
      brand: 'Brand',

      base_category: 'Base category',
      add_category: 'Add category',
      category_registration: 'Category registration',

      register: 'Registration',
      editing: 'Editing',
      form_title: '{{mode}} of product',
      unit_cost: 'Unit cost',

      unit_quantity: 'Quantity',
      quantity: 'Quantity',
      note: 'Note',
      add_stock: 'Add to stock',
      remove_stock: 'Remove from stock',
      update_stock: 'Update stock',

      stock_unavailable: 'Available quantity: {{value}}',

      stock_success_message: 'Stock has been updated',
      product_category_success_message: 'The category has been saved',

      was_enabled_disabled: 'The product has been $t({{action}}).',
      was_favorited: 'The product has been $t({{action}}) favorites.',
      was_saved: 'Product has been saved.',
      tax_management: 'Tax management',

      export: 'Export XLS table',
    },
    pdv: {
      title: 'Latest sales',
      date_hour: 'Date/Time',
      product: 'Product',
      value: 'Amount',
      operator: 'Operator',
      sales_list: 'Sales list',
      identifier: 'Identifier',
      order_code: 'Order code',
    },
    employee: {
      title: 'Employee',
      plural_title: 'Employees',
      invite: 'Invite employee',
      delete: 'Remove employee',
      edit: 'Edit employee',
      change_operation_code: 'Change operator code',
      operation_code: 'Operator code',
      standby: 'Standby',
      product_image: 'Employee image',
      remove_confirmation:
        'Are you sure you want to remove the employee {{employeeName}}?',
      status_change_confirmation:
        'Are you sure you want to {{action}} the employee {{employeeName}}?',
      show_hide_all: '{{action}} all',
      waiting: 'Waiting',
      others: 'Others',
      roles: 'Roles',
      updated_at: 'Updated {{count}} day ago',
      updated_at_plural: 'Updated {{count}} days ago',
      updated_at_reduce: 'Updated $t({{day}})',
      expires_in: 'Expires in {{count}} day',
      expires_in_plural: 'Expires in {{count}} days',
      expires_in_reduce: 'Expires $t({{day}})',
      send_invites: 'Send invites',
      invite_employees: 'Invite employees',
      employee_role: 'Employee role',
      was_enabled_disabled: 'The employee has been $t({{action}}).',

      // FORM FIELDS
      image: 'Image',
      name: 'Name',
      phone: 'Phone',
      email: 'E-mail',
      role: 'Role',
      status: 'Status',
    },
    establishment: {
      title: 'Establishment',
      plural_title: 'Establishments',

      my_establishments: 'My establishments',
      no_relationship: 'You are not yet linked to any establishment.',
      create: 'Insert establishment',

      contact: 'Contact',

      operatorCode: 'Operator code',
      relationship: 'Relationship',
      logo_placeholder: 'Logo placeholder',
      logo: 'Logo',
      email_icon: 'E-mail icon',
      phone_icon: 'Phone icon',
      my_relationship: 'My relationship',
      association_data: 'Relationship data',
      entry_into: 'Entry on',
      entry_date: 'Entry date',
      function: 'Function',

      // ## FORM
      save: 'Save',
      saving: 'Saving',
      general: 'General',
      preferences: 'Preferences',
      remove_relationship_confirmation:
        'Are you sure you want to leave this establishment?',
      remove_confirmation:
        'Are you sure you want to remove this establishment?',

      // Preferences
      min_value_in_card_payment: 'Minimum payment by card',
      audience_capacity: 'Audience capacity',
      audience_capacity_count: '{{count}} person',
      audience_capacity_count_plural: '{{count}} people',
      age_group: 'Age group',
      convenience_fee_percentage: 'Convenience fee percentage',
      show_brands_in_payment: 'Brand detailing',
      device_will_transact: 'Off-line acquiring',
      reversal_in_cash: 'Reversal in cash',
      cash_management: 'Cash management',
      sale_installments: 'Installment sale of products',
      card_machine_mode: 'Card machine mode',
      print_qr_code: 'Print QR Code',
      operation_mode: 'Operation mode',
      operation_category: 'Operation category',
      seller_id: 'Seller ID',
      configuration_with_zoop: 'Zoop configuration',

      // ## FORM
      register: 'Registration',
      editing: 'Editing',
      form_title: '{{mode}} of establishment',

      // sections
      identity: 'Identity',
      public_contact: 'Public contact',
      address: 'Address',
      legal_responsible: 'Managing partner',
      tax: 'Tax',
      national_simple: 'National simple',
      managing_partner: 'Managing partner',
      how_to_get: 'How to get',

      // fields
      trade_name: 'Trade name',
      accent_color: 'Accent color (eg. #582c8b)',
      description: 'Description',
      web_site: 'Web site',
      document: 'Document',
      contact_email: 'Contact e-mail',
      email: 'E-mail',
      contact_phone: 'Contact phone',
      phone: 'Phone',
      abreviated_phone: 'Phone',
      company_name: 'Company name',
      state_registration: 'State registration',
      town_registration: 'Town registration',
      postal_code: 'Postal code',
      state: 'State',
      city: 'City',
      neighborhood: 'Neighborhood',
      eg_line1: 'Line 1 (e.g St. Avenue)',
      line1: 'Line 1',
      line2: 'Line 2',
      line3: 'Line 3',
      full_name: 'Full name',
      partner_full_name: 'Full name',
      partner_document: 'Document',
      partner_birthdate: 'Birthdate',
      insert_new_establishment: 'Insert a new establishment',

      // ## END FORM

      establishment_was_removed: 'The establishment has beed removed',
      establishment_not_found: 'Establishment not found',
      you_leave_establishment: 'You leave the establishment',
      establishment_successfully_saved: 'The establishment has been saved',
      integration_configured: 'Successfully configured integration',
    },
    invite: {
      title: 'Invite',
      plural_title: 'Invites',
      no_invites: 'You have no invitation',
      accepted_invite: 'Invitation accepted ',
      welcome_to_establishment:
        'Welcome to the team of employees of the establishment',
      your_user_has_permissions: 'Your user has the following permissions ',
      send_another_code: 'Send another code',
      no_avaible_code:
        'Remember to be logged in with the same e-mail or phone number as the invitation',
      cancel_invite: 'Cancel invitation',
      invite_accepted: 'Invitation has been accepted',
      invite_rejected: 'Invitation has been rejected',
      invite_resent: 'Invitation has been resent',
      invite_canceled: 'Invitation has been canceled',
      invites_sent: 'Invitations have been sent',
      no_code_provided: 'No invitation code was provided',
      select_at_least_one_email_phone: 'Select at least one email and/or phone',
      select_at_least_one_role: 'Select at least one role',
      invalid_phone: 'The phone number provided is not a valid phone number',
      invalid_email: 'The email provided is not a valid email',
      data_already_inserted: 'This data has already been entered',
      permissions_have_been_updated: 'Permissions have been updated',
      unexpected_error:
        'An unexpected error has occurred. \nPlease try again or contact the establishment manager.',
      counter: '{{count}} invite',
      counter_plural: '{{count}} invites',
      invalid_code:
        'This invitation does not exist or does not belong to this user.',
      log_in_with_another_account: 'Log in with different account',
    },
    auth: {
      sign_in: 'Login',
      welcome_to: 'Welcome to',
      email: 'E-mail',
      phone: 'Phone',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      document: 'Document',
      name: 'Name',
      dont_have_account: 'Not registered yet?',
      register_clicking_here: 'Register by clicking here!',
      best_establishments_are_here: 'The best establishments are here.',
      log_in_using: 'Log in using your ',
      register_using: 'Register using your ',
      create_your_account_and_begin_to_use: 'Register and start to use the ',
      keep_connected: 'Keep connected',
      forgot_password: 'Forgot password?',
      phone_min_length:
        'Your phone must contain at least {{minLength}} digits, including country code and area code',
      complete_with_phone_email_to: 'Complete with your email or phone to ',
      recover_your_password: 'recover your password.',
      insert_your: 'Insert your ',
      resend_code: 'Resend code',
      already_confirmed_by_link: 'I already confirmed by the link',
      insert_the_code_sent: 'Enter the code sent',
      validate_your_registration_below: 'Validate your registration below.',
      little_missing: 'Little missing!',
      little_missing_to_change_password:
        'You have a little while to change your password!',
      confirmation_code_not_provided: 'Confirmation code not provided',
      request_another_code: 'Request another code',
      complete_registration: 'Complete registration',
      and_start_to_operate_system: 'and start to operate the FanPDV!',
      insert_yout_details: 'Insert your details.',
      insert_your_new_password: 'Insert your new password',
      passwords_dont_match: 'Passwords entered are different.',
      you_have_no_permission:
        'You are not allowed to access the requested resource.',
      email_or_password_is_incorrect: 'Email or password is incorrect',
      field_already_registered:
        'There is already an account linked to the $t({{context}}) entered',
    },
    integration: {
      title: 'Integration',
      plural_title: 'Integrations',
      image: 'Integration image',
      zoop_configuration: 'Zoop configuration',
      seller_id: 'Seller ID',
      fanapp_configuration: 'FanApp configuration',
      enotas_configuration: 'ENotas configuration',
      access_token: 'Access token',
      disable: 'Disable integration',
      disabled: 'Integration was disabled',
      accounting_email: 'Accounting email',
      id: 'Id',
      code: 'Code',
      production: 'Production',
      homologation: 'Homologation',
      nfe_serie: 'NFe sequence',
      nfe_sequence: 'NFe sequence',
      show_csc_config: 'Show CSC config',
      disable_confirmation:
        'Are you sure you want to remove the integration/module {{integrationName}}?',
      stock: 'Stock',
    },
    general: {
      configuration: 'Configuration',
      publish: 'Publish',
      publishing: 'Publishing',
      done: 'Done',
      conclude: 'Conclude',
      access: 'Access',
      loading: 'Loading',
      edit: 'Edit',
      configure: 'Configure',
      save: 'Save',
      saving: 'Saving',
      enable: 'Enable',
      disable: 'Disable',
      sending: 'Sending',
      send: 'Send',
      resend: 'Resend',
      disconnect: 'Disconnect',
      price_prefix: '$',
      reject: 'Reject',
      accept: 'Accept',
      fanpdv_logo: 'FanPDV logo',
      welcome: 'Welcome',
      ops: 'Ops',
      avatar: 'Avatar',
      show: 'Show',
      hide: 'Hide',
      today: 'Today',
      tomorrow: 'Tomorrow',
      yesterday: 'Yesterday',
      update: 'Update',
      enter: 'Enter',
      cancel: 'Cancel',
      confirm: 'Confirm',
      back: 'Back',
      alert_image: 'Alert image',
      success: 'Success!',
      error: 'Error!',
      actions: 'Actions',
      drop_image_here: 'Drop image here',
      drag_and_drop_file_or_click: 'Drag and drop a file or click to add',
      enabled: 'enabled',
      disabled: 'disabled',
      added_to: 'added to',
      removed_from: 'removed from',
      see_all: 'See all',
      search: 'Search',
      back_icon: 'Back icon',
      eg: '(Eg: {{eg}})',
      select: 'Selecione...',
    },
    general_messages: {
      request_error: 'There was an error with the request. Try again.',
      page_not_found: 'Page not found',
      url_does_not_exist:
        'The requested address does not exist or is no longer available',
      code_successfully_sent: 'Code successfully sent!',
      was_removed: 'The $t({{context}}) has been removed.',
      was_updated: 'The $t({{context}}) has been updated.',
      was_saved: 'The $t({{context}}) has been saved.',
    },
    validation_messages: {
      required_field: 'Field $t({{field}}) is required',
      out_of_the_pattern: 'field $t({{field}}) is out of the pattern',
      only_letters_and_symbols:
        'Field $t({{field}}) must contain only letters and symbols',
      invalid_field: 'Field $t({{field}}) is invalid',
      invalid_date: 'Field $t({{field}}) is a invalid date',
      max_characters:
        'Field $t({{field}}) must contain a maximum of {{maxLength}} characters',
      min_characters:
        'Field $t({{field}}) must contain a minimum of {{minLength}} characters',
      between_characters:
        'Field $t({{field}}) must contain a minimum of {{minLength}} and a maximum of {{maxLength}} characters',
      must_be_in_format: 'Field $t({{field}}) must be in format {{format}}',
      must_have_only_letters: 'Field $t({{field}}) must contain only letters',
      must_be_a_number: 'Field $t({{field}}) must contain numbers only',
      exact_characters:
        'Field $t({{field}}) must contain exactly {{length}} characters',
      please_use_format: 'Please use {{format}} format',
      number_must_be_grater_than:
        'Field $t({{field}}) must be a number grater than {{length}}!',
      number_must_be_grater_than_field:
        'Field $t({{grater}}) must be a number grater than {{smaller}}!',
      date_must_be_grater_than_field:
        'Field $t({{grater}}) must be a date after {{smaller}}!',
      date_must_be_grater_than_today:
        'Field $t({{grater}}) must be a date after today!',
      date_must_be_smaller_than_field:
        'Field $t({{smaller}}) must be a date before than {{grater}}!',
      file_max_size: 'Please select a file less than {{maxFileSize}}mb.',
      password_pattern:
        'Password field must contain at least one uppercase letter, one lowercase letter and one number.',
    },
    masks: {
      full_phone: '(+55) (99) 99999-9999',
      document: '99.999.999/9999-99',
      partner_document: '999.999.999-99',
      postal_code: '99999-999',
      date: '99/99/9999',
      date_time: '99/99/9999 99:99',
    },
    icons: {
      beer: 'beer',
      bubble_gum: 'bubble gum',
      burguer: 'burguer',
      caipirinha: 'caipirinha',
      candy: 'candy',
      champagne: 'champagne',
      cigarrette: 'cigarrette',
      cocktail: 'cocktail',
      combo: 'combo',
      dessert: 'dessert',
      distilled: 'distilled',
      drink: 'drink',
      energetic: 'energetic',
      finger_food: 'finger food',
      food: 'food',
      gin: 'gin',
      juice: 'juice',
      liquor: 'liquor',
      main_dish: 'main dish',
      martini: 'martini',
      meat: 'meat',
      other: 'other',
      pasta: 'pasta',
      pizza: 'pizza',
      popsicle: 'popsicle',
      seafood: 'seafood',
      snacks: 'snacks',
      soda: 'sosodada',
      starter: 'starter',
      sweet: 'sweet',
      vermouth: 'vermouth',
      vodka: 'vodka',
      water: 'water',
      whisky: 'whisky',
      wine: 'wine',
    },
    category_base: {
      absinthe: 'Absinthe',
      cereal_bar: 'Cereal bar',
      savory_snacks: 'Savory snacks',
      caipirinha: 'Caipirinha',
      campari: 'Campari',
      martini: 'Martini',
      rum: 'Rum',
      vodka: 'Vodka',
      tequila: 'Tequila',
      whisky: 'Whisky',
      cereal: 'Cereal',
      burguer: 'Hambúrguer',
      pizza: 'Pizza',
      salgado: 'Salgado',
      barra: 'Savory snacks',
      candy: 'Candy',
      cocktail: 'Cocktail',
      dairy_beverage: 'Dairy Beverage',
      cookie: 'Cookie',
      cake: 'Cake',
      hot_dog: 'Hot Dog',
      broth: 'Broth',
      coffe: 'Coffe',
      meat: 'Meat',
      beer: 'Beer',
      champagne: 'Champagne',
      bubble_gum: 'Bubble Gum',
      cigarrette: 'Cigarrette',
      brandy: 'Brandy',
      distilled: 'Distilled',
      energetic: 'Energetic',
      sparkling_wine: 'Sparkling wine',
      starter: 'Starter',
      sweet: 'Sweet',
      snacks: 'Snacks',
      sea_food: 'Sea Food',
      gin: 'Gin',
      fruit: 'Fruit',
      liquor: 'Liquor',
      pasta: 'Pasta',
      grilled: 'Grilled',
      other: 'Other',
      fried_pastry: 'Fried Pastry',
      finger_food: 'Finger Food',
      popsicle: 'Popsicle',
      main_dish: 'Main Dish',
      soda: 'Soda',
      dessert: 'Dessert',
      sandwich: 'Sandwich',
      juice: 'Juice',
      pie: 'Pie',
      vermouth: 'Vermouth',
      smoothie: 'Smoothie',
      wine: 'Wine',
      water: 'Water',
    },
  },
};
