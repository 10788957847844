import { memo, useState, useEffect, useCallback } from 'react';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import api from 'services/api';

import { EstablishmentState } from 'interfaces/establishment';
import { notification } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { translate } from 'config/i18n';

import ClockIcon from 'assets/svg/clock.svg';
import CalendarIcon from 'assets/svg/calendar.svg';

import { Operation } from 'interfaces/operation';
import { parseISO, format } from 'date-fns';
import { IOperationCategory, IOperationMode } from 'helpers/mapper';
import {
  Header,
  Button,
  Content,
  SeeMoreButton,
  DateTimeContainer,
} from './styles';
import OpenOperationModal from './OpenOperationModal';
import CloseOperationModal from './CloseOperationModal';
import DetailsModal from './DetailsModal';

interface IOperation {
  id: string;
  name: string;
}

function Operations() {
  const [operations, setOperations] = useState<Array<any>>([]);
  const [rawOperations, setRawOperations] = useState<Array<Operation>>([]);
  const [loading, setLoading] = useState(true);
  const [openedOperation, setOpenedOperation] = useState<Operation | null>(
    null,
  );
  const [operationSelected, setOperationSelected] = useState({} as Operation);
  const [total, setTotal] = useState(0);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [openOperationModalVisible, setOpenOperationModalVisible] = useState(
    false,
  );
  const [closeOperationModalVisible, setCloseOperationModalVisible] = useState(
    false,
  );
  const [operationModesList, setOperationModeList] = useState<
    Array<IOperation>
  >([]);
  const [operationCategoriesList, setOperationCategoriesList] = useState<
    Array<IOperation>
  >([]);

  const columns: any = [
    {
      title: translate('operation.opening'),
      dataIndex: 'openedAt',
    },
    {
      title: translate('operation.closure'),
      dataIndex: 'closedAt',
    },
  ];

  const formatOperationDate = (dateString: string) => {
    if (!dateString) return '';
    const dateTime = parseISO(dateString);
    const date = format(dateTime, 'dd/MM/yyyy');
    const time = format(dateTime, 'HH:mm');

    return (
      <DateTimeContainer>
        <div>
          <img src={CalendarIcon} alt="calendar-icon" />
          {date}
        </div>
        <div>
          <img src={ClockIcon} alt="clock-icon" />
          {time}
        </div>
      </DateTimeContainer>
    );
  };

  useEffect(() => {
    async function getAuxLists() {
      const [
        operationModesResponse,
        operationCategoriesResponse,
      ] = await Promise.all([
        api.get('trade/operation-modes'),
        api.get('trade/operation-categories'),
      ]);

      setOperationModeList(
        operationModesResponse.data.data.map((x: IOperation) => ({
          ...x,
          name: IOperationMode[x.name],
        })),
      );

      setOperationCategoriesList(
        operationCategoriesResponse.data.data.map((x: IOperation) => ({
          ...x,
          name: IOperationCategory[x.name],
        })),
      );
    }

    getAuxLists();
  }, [currentEstablishment]);

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);

        const openedOperationResponse = await api.get(
          `/trade/establishments/${currentEstablishment?.id}/operations/open`,
        );

        if (Object.keys(openedOperationResponse.data.data).length === 0)
          setOpenedOperation(null);
        else setOpenedOperation(openedOperationResponse.data.data);

        const response = await api.get(
          `trade/establishments/${currentEstablishment?.id}/operations?page=${
            page || 1
          }&pageSize=${pageSize || defaultPageSize}`,
        );

        setTotal(response.headers['x-total'] || 0);

        formatOperationDate('2021-09-01T22:04:04.872Z');

        setRawOperations(response.data.data);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  useEffect(() => {
    async function getOperationsFromServer() {
      handleChangeTable();
    }

    getOperationsFromServer();
  }, [currentEstablishment, handleChangeTable]);

  useEffect(() => {
    setOperations(
      rawOperations.map((x: Operation) => ({
        ...x,
        openedAt: formatOperationDate(x.openedAt),
        closedAt: formatOperationDate(x.closedAt),
        actions: (
          <SeeMoreButton
            className="btn-clean"
            onClick={() => {
              setOperationSelected(x);
              setDetailsModalVisible(true);
              /**/
            }}
          >
            ver mais
          </SeeMoreButton>
        ),
      })),
    );
  }, [rawOperations]);

  return (
    <Page
      displayDrawer
      title={translate('operation.plural_title')}
      tabs={[
        {
          label: translate('operation.plural_title'),
          route: '/operations',
        },
      ]}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              if (!openedOperation) setOpenOperationModalVisible(true);
              else setCloseOperationModalVisible(true);
            }}
            htmlType="button"
            type="primary"
            size="middle"
          >
            {translate(
              `${!openedOperation ? 'operation.open' : 'operation.close'}`,
            )}
          </Button>
        </Header>
      }
    >
      <Content>
        <DataTable
          total={total}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={operations}
        />

        <OpenOperationModal
          visible={openOperationModalVisible}
          setVisible={setOpenOperationModalVisible}
          currentEstablishment={currentEstablishment!}
          callback={handleChangeTable}
        />

        <CloseOperationModal
          visible={closeOperationModalVisible}
          setVisible={setCloseOperationModalVisible}
          operationId={openedOperation?.id}
          callback={handleChangeTable}
        />

        <DetailsModal
          visible={detailsModalVisible}
          setVisible={setDetailsModalVisible}
          operation={operationSelected}
          operationModesList={operationModesList}
          operationCategoriesList={operationCategoriesList}
        />
      </Content>
    </Page>
  );
}

export default memo(Operations);
