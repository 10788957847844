import { memo, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Establishment } from 'interfaces/establishment';
import { useDispatch } from 'react-redux';
import { setCurrentEstablishment } from 'store/ducks/establishment';
import {
  formatCnpj,
  formatCpf,
  formatPhone,
  getEstablishmentName,
  getNamePlaceholder,
} from 'helpers/stringHelper';
import { translate } from 'config/i18n';
import { useQuery } from 'hooks/useQuery';
import { usePermissions } from 'hooks/usePermissions';
import { Roles } from 'constants/roles';
import { Button, Card, Placeholder } from './styles';

interface IEstablishmentCard {
  establishment: Establishment;
}

function EstablishmentCard({ establishment }: IEstablishmentCard) {
  const dispatch = useDispatch();
  const { addCurrentEstablishment, has } = usePermissions();
  const history = useHistory();
  const query = useQuery();
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  function handleAccessEstablishment() {
    addCurrentEstablishment(establishment);

    const homeUri = establishment.employees[0]?.roles.find(
      x =>
        x.name === Roles.ADMIN ||
        x.name === Roles.FINANCIAL_MANAGER ||
        x.name === Roles.OPERATIONAL_MANAGER,
    )
      ? '/dashboard'
      : establishment.employees[0]?.roles.find(x => x.name === Roles.STOCKIST)
      ? '/products'
      : '';

    dispatch(
      setCurrentEstablishment(establishment, () =>
        history.push(redirectUrl || homeUri),
      ),
    );
  }

  const formattedPhone = useMemo(() => formatPhone(establishment.phone), [
    establishment,
  ]);

  return (
    <Card>
      <div>
        <Placeholder>
          {establishment.logo ? (
            <div
              style={{ backgroundImage: `url(${establishment.logo.location})` }}
            />
          ) : (
            <span>{getNamePlaceholder(establishment)}</span>
          )}
        </Placeholder>
      </div>

      <div>
        <span className="title">{getEstablishmentName(establishment)}</span>
        <table>
          <tbody>
            <tr>
              <td>{translate('establishment.document')}</td>
              <td>
                {establishment.document?.length > 14
                  ? formatCnpj(establishment.document)
                  : formatCpf(establishment.document)}
              </td>
            </tr>
            <tr>
              <td>{translate('establishment.email')}</td>
              <td className="email-div">
                {establishment.email}
                <div className="blur" />
              </td>
            </tr>
            <tr>
              <td>{translate('establishment.phone')}</td>
              <td>{formattedPhone}</td>
            </tr>
          </tbody>
        </table>
        <div className="footer">
          <Button
            onClick={() =>
              history.push(`/establishments/relationship/${establishment.id}`)
            }
            htmlType="button"
            type="link"
          >
            {translate('establishment.my_relationship')}
          </Button>
          {has(
            [],
            [
              Roles.ADMIN,
              Roles.FINANCIAL_MANAGER,
              Roles.OPERATIONAL_MANAGER,
              Roles.STOCKIST,
            ],
            establishment,
          ) && (
            <Button
              onClick={handleAccessEstablishment}
              htmlType="button"
              type="primary"
              size="small"
            >
              {translate('general.access')}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}

export default memo(EstablishmentCard);
