interface IMapper {
  [key: string]: string;
}

interface IHierarchy {
  role: string;
  weight: number;
}

const rolesHierarchy: IHierarchy[] = [
  {
    role: 'admin',
    weight: 1,
  },
  {
    role: 'operationalManager',
    weight: 2,
  },
  {
    role: 'financialManager',
    weight: 2,
  },
  {
    role: 'cashier',
    weight: 3,
  },
  {
    role: 'stockist',
    weight: 3,
  },
  {
    role: 'validator',
    weight: 3,
  },
];

const establishmentRole: IMapper = {
  operationalManager: 'Gerente Operacional',
  financialManager: 'Gerente Financeiro',
  cashier: 'Caixa',
  validator: 'Validador',
  stockist: 'Estoquista',
  admin: 'Administrador',
};

const inviteStatus: IMapper = {
  created: 'Criado',
  accepted: 'Aceito',
  rejected: 'Rejeitado',
  canceled: 'Cancelado',
  expired: 'Expirado',
};

const IOperationMode: IMapper = {
  direct: 'Venda direta',
};

const IOperationCategory: IMapper = {
  congress_seminary: 'Congresso, seminário',
  gastronomic: 'Gastronômico',
  course_workshop: 'Curso, workshop',
  meeting_networking: 'Encontro, networking',
  sport: 'Esportivo',
  fair_exhibition: 'Feira, exposição',
  movie_theater: 'Filme, cinema e teatro',
  religious_spiritual: 'Religioso, espiritual',
  concert_music_party: 'Show, música e festa',
  esport: 'E-sports',
  other: 'Outro evento',
};

export {
  establishmentRole,
  inviteStatus,
  IOperationMode,
  IOperationCategory,
  rolesHierarchy,
};
