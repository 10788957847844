import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Switch, notification } from 'antd';
import { BgColorsOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { EstablishmentState } from 'interfaces/establishment';
import { create, update, EstablishmentInput } from 'store/ducks/establishment';
import { translate } from 'config/i18n';

import InputMask from 'react-input-mask';
import { Store } from 'antd/lib/form/interface';
import { TwitterPicker } from 'react-color';
import cep from 'cep-promise';
import api from 'services/api';
import Page from 'components/Page';
import Dropzone from 'components/Dropzone';

import { isCNPJ, isCPF, isValidDate } from 'services/validation';
import { secureGoBack } from 'helpers/routeHelper';
import { gutter } from 'constants/styles';
import PhoneInput from 'react-phone-input-2';
import { format, parse } from 'date-fns';
import {
  FormContainer,
  Section,
  SectionTitle,
  Input,
  Select,
  SwitchContainer,
  Header,
  Button,
  SearchIcon,
  ColorBoxInput,
  Label,
} from './styles';

interface IStateResult {
  id: number;
  sigla: string;
  nome: string;
}

interface INavParams {
  establishmentId: string;
}

function EstablishmentForm(): JSX.Element {
  const { establishmentId } = useParams<INavParams>();
  const [form] = Form.useForm();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { loading } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const dispatch = useDispatch();

  const [establishment, setEstablishment] = useState({} as EstablishmentInput);
  const [brandColor, setBrandColor] = useState('');
  const [logoPreview, setLogoPreview] = useState<string | null | undefined>('');
  const [imageData, setImageData] = useState<File | null>(null);
  const [stateList, setStateList] = useState<Array<IStateResult>>([]);
  const [defaultSchema, setDefaultSchema] = useState('http://');
  const [isLegalPerson, setIsLegalPerson] = useState(true);
  const [preLoading, setPreLoading] = useState(true);
  const [cepLoading, setCepLoading] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);

  const selectBefore = (
    <Select
      defaultValue={defaultSchema}
      id="web-site-scheme"
      className="select-before"
    >
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https://</Select.Option>
    </Select>
  );

  useEffect(() => {
    function handlePickerVisible(e: MouseEvent) {
      const pickerIcon = document.getElementsByClassName('picker-icon')[0];
      if (pickerIcon) {
        const svg = pickerIcon.getElementsByTagName('svg')[0];
        const path = pickerIcon.getElementsByTagName('path')[0];

        if (e.target !== pickerIcon && e.target !== svg && e.target !== path) {
          setPickerVisible(false);
        }
      }
    }
    document.addEventListener('click', handlePickerVisible);

    return () => {
      document.removeEventListener('click', handlePickerVisible);
    };
  }, []);

  useEffect(() => {
    async function getStateList() {
      // TODO: conferir se existe algum padrão de endpoint para pegar estados.
      const { data } = await api.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
      );

      setStateList(
        data
          .sort((a: IStateResult, b: IStateResult) =>
            a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0,
          )
          .map((state: IStateResult) => ({
            id: state.id,
            sigla: state.sigla,
            nome: state.nome,
          })),
      );
    }

    async function getEstablishmentFromServer() {
      const { data } = await api.get(`trade/establishments/${establishmentId}`);

      setLogoPreview(data.data.logo?.location || '');

      const [schema, website] = data.data.website
        ? data.data.website.split('//')
        : ['http:', ''];

      const formattedPartnerBirthdate = format(
        parse(data.data.partnerBirthdate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy',
      );

      setDefaultSchema(`${schema}//`);
      setEstablishment({
        ...data.data,
        website,
        partnerBirthdate: formattedPartnerBirthdate,
      });
      setBrandColor(data.data.brandColor);
      setPreLoading(false);
      setIsLegalPerson(!!data.data.document);
    }

    getStateList();
    if (establishmentId) getEstablishmentFromServer();
    else setPreLoading(false);
  }, [establishmentId]);

  const handleForm = useCallback(
    (data: Store) => {
      function handleSuccess(backRoute: string) {
        notification.success({
          message: translate('general.success'),
          description: translate(
            'establishment.establishment_successfully_saved',
          ),
        });

        secureGoBack(history, lastLocation, backRoute);
      }

      const schema = document.getElementsByClassName('select-before')[0]
        ?.textContent;

      const input: EstablishmentInput = {
        id: establishmentId,
        email: data.email,
        phone: `+${data.phone.replace(/\D+/g, '')}`,
        postalCode: data.postalCode.replace('-', ''),
        line1: data.line1,
        line2: data.line2,
        neighborhood: data.neighborhood,
        city: data.city,
        state: data.state,
        partnerName: data.partnerName,
        partnerDocument: data.partnerDocument.replace(/\D+/g, ''),
        partnerBirthdate: data.partnerBirthdate,
        couvert: 1,
        transactionLimit: 1,
      };

      if (isLegalPerson) {
        if (data.document) input.document = data.document.replace(/\D+/g, '');
        if (data.companyName) input.companyName = data.companyName;
        if (data.stateRegistration)
          input.stateRegistration = data.stateRegistration;
        if (data.townRegistration)
          input.townRegistration = data.townRegistration;
      } else {
        input.document = undefined;
        input.companyName = undefined;
        input.stateRegistration = undefined;
        input.townRegistration = undefined;
      }

      if (data.brandColor) input.brandColor = data.brandColor;
      if (data.tradeName) input.tradeName = data.tradeName;
      if (data.line3) input.line3 = data.line3;
      if (data.description) input.description = data.description;
      if (data.website && schema) input.website = schema + data.website;

      if (!establishmentId) {
        dispatch(
          create(input, imageData, () => handleSuccess('/establishment')),
        );
      } else {
        dispatch(
          update(
            input,
            imageData,
            () => handleSuccess('/establishment'),
            !logoPreview,
          ),
        );
      }
    },
    [
      dispatch,
      establishmentId,
      history,
      lastLocation,
      isLegalPerson,
      imageData,
      logoPreview,
    ],
  );

  const handleChangeCEP = useCallback(
    async e => {
      const cepRaw = e.target.value.replace('_', '').replace('-', '');

      if (cepRaw.length === 8) {
        setCepLoading(true);

        try {
          const { state, city, neighborhood, street } = await cep(cepRaw);

          form.setFieldsValue({
            state,
            city,
            neighborhood,
            line1: street,
          });
          setCepLoading(false);
        } catch {
          setCepLoading(false);
        }
      }
    },
    [form],
  );

  // useEffect(() => form.resetFields(), [ establishment, form ]);

  const handleColorPicker = () => (
    <BgColorsOutlined
      className="picker-icon"
      onClick={() => setPickerVisible(true)}
    />
  );

  const handleImageDrop = useCallback(acceptedFiles => {
    const imgData = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setLogoPreview(reader?.result?.toString());
      setImageData(imgData);
    };
    reader.readAsDataURL(imgData);
  }, []);

  return (
    <Page
      displayDrawer={!!establishmentId}
      title={translate('establishment.form_title', {
        mode: establishmentId
          ? translate('establishment.editing')
          : translate('establishment.register'),
      })}
      showBackButton
      loading={preLoading}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              secureGoBack(
                history,
                lastLocation,
                establishmentId ? '/establishment' : '/dashboard',
              );
            }}
            htmlType="button"
            type="default"
          >
            {translate('general.cancel')}
          </Button>

          <Button
            disabled={loading}
            onClick={() => {
              form.submit();
            }}
            htmlType="submit"
            type="primary"
          >
            {loading ? translate('general.saving') : translate('general.save')}
          </Button>
        </Header>
      }
    >
      <FormContainer>
        <Form form={form} initialValues={establishment} onFinish={handleForm}>
          <Section>
            <SectionTitle>{translate('establishment.identity')}</SectionTitle>

            <Row gutter={gutter}>
              <Col sm={20} md={6}>
                <Dropzone
                  alt={translate('establishment.logo')}
                  onDropAccepted={handleImageDrop}
                  onClean={(e: any) => {
                    e.stopPropagation();
                    setLogoPreview('');
                  }}
                  logoPreview={logoPreview}
                />
              </Col>

              <Col sm={20} md={14}>
                <Label>{translate('establishment.trade_name')} </Label>
                <Form.Item name="tradeName">
                  <Input />
                </Form.Item>

                <ColorBoxInput color={brandColor}>
                  <Form.Item
                    name="brandColor"
                    rules={[
                      {
                        pattern: new RegExp(
                          '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$',
                        ),
                        message: translate(
                          'validation_messages.please_use_format',
                          { format: '#582c8b' },
                        ),
                      },
                    ]}
                  >
                    <Input
                      addonBefore={handleColorPicker()}
                      placeholder={translate('establishment.accent_color')}
                      maxLength={7}
                      onChange={e => setBrandColor(e.target.value)}
                    />
                  </Form.Item>
                  {pickerVisible && (
                    <TwitterPicker
                      className="picker"
                      color={brandColor}
                      onChangeComplete={({ hex }) => {
                        form.setFieldsValue({ brandColor: hex });
                        setBrandColor(hex);
                      }}
                    />
                  )}
                </ColorBoxInput>

                <Form.Item name="description">
                  <Input.TextArea
                    placeholder={translate('establishment.description')}
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>
              {translate('establishment.public_contact')}
            </SectionTitle>

            <Row gutter={gutter}>
              <Col sm={20}>
                <Form.Item name="website">
                  <Input
                    addonBefore={selectBefore}
                    placeholder={translate('establishment.web_site')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.contact_email')}</Label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.email',
                      }),
                    },
                    {
                      type: 'email',
                      message: translate('validation_messages.invalid_field', {
                        field: 'establishment.email',
                      }),
                    },
                    {
                      max: 130,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.email',
                        maxLength: '130',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.contact_phone')}</Label>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.phone',
                      }),
                    },
                  ]}
                >
                  <PhoneInput country="br" inputClass="ant-input" />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>Fiscal</SectionTitle>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <SwitchContainer>
                  <Switch
                    defaultChecked={isLegalPerson}
                    onChange={() => {
                      setIsLegalPerson(!isLegalPerson);
                    }}
                    checked={isLegalPerson}
                  />
                  <span>Pessoa jurídica</span>
                </SwitchContainer>
              </Col>

              <Col sm={20} md={10}>
                <Label>{translate('establishment.document')}</Label>
                <Form.Item
                  name="document"
                  rules={[
                    {
                      required: isLegalPerson,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.document',
                      }),
                    },
                    { validator: isCNPJ, validateTrigger: 'onBlur' },
                  ]}
                >
                  <InputMask
                    mask={translate('masks.document')}
                    disabled={!isLegalPerson}
                  >
                    <Input />
                  </InputMask>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20}>
                <Label>{translate('establishment.company_name')}</Label>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: isLegalPerson,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.company_name',
                      }),
                    },
                    {
                      max: 60,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.company_name',
                        maxLength: '60',
                      }),
                    },
                  ]}
                >
                  <Input disabled={!isLegalPerson} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.state_registration')}</Label>
                <Form.Item
                  name="stateRegistration"
                  rules={[
                    {
                      required: isLegalPerson,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.email',
                      }),
                    },
                  ]}
                >
                  <Input disabled={!isLegalPerson} />
                </Form.Item>
              </Col>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.town_registration')}</Label>
                <Form.Item
                  name="townRegistration"
                  rules={[
                    {
                      required: isLegalPerson,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.email',
                      }),
                    },
                  ]}
                >
                  <Input disabled={!isLegalPerson} />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>{translate('establishment.address')}</SectionTitle>

            <Row gutter={gutter}>
              <Col sm={20}>
                <Form.Item
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.postal_code',
                      }),
                    },
                  ]}
                >
                  <InputMask
                    mask={translate('masks.postal_code')}
                    onChange={handleChangeCEP}
                  >
                    <Input
                      prefix={<SearchIcon />}
                      placeholder={`${translate('establishment.postal_code')}*`}
                    />
                  </InputMask>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.state')}</Label>
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.state',
                      }),
                    },
                  ]}
                >
                  <Select
                    placeholder={`${translate('general.select')}*`}
                    disabled={cepLoading}
                  >
                    {stateList.map(state => (
                      <Select.Option key={state.id} value={state.sigla}>
                        {state.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.city')}</Label>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.city',
                      }),
                    },
                    {
                      max: 60,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.city',
                        maxLength: '60',
                      }),
                    },
                  ]}
                >
                  <Input disabled={cepLoading} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.neighborhood')}</Label>
                <Form.Item
                  name="neighborhood"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.neighborhood',
                      }),
                    },
                    {
                      max: 60,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.neighborhood',
                        maxLength: '60',
                      }),
                    },
                  ]}
                >
                  <Input disabled={cepLoading} />
                </Form.Item>
              </Col>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.eg_line1')}</Label>
                <Form.Item
                  name="line1"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.line1',
                      }),
                    },
                    {
                      max: 120,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.line1',
                        maxLength: '120',
                      }),
                    },
                  ]}
                >
                  <Input disabled={cepLoading} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.line2')}</Label>
                <Form.Item
                  name="line2"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.line2',
                      }),
                    },
                    {
                      max: 60,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.line2',
                        maxLength: '60',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.line3')}</Label>
                <Form.Item name="line3">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>
              {translate('establishment.legal_responsible')}
            </SectionTitle>

            <Row gutter={gutter}>
              <Col sm={20}>
                <Label>{translate('establishment.full_name')}</Label>
                <Form.Item
                  name="partnerName"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.partner_full_name',
                      }),
                    },
                    {
                      max: 130,
                      message: translate('validation_messages.max_characters', {
                        field: 'establishment.partner_full_name',
                        maxLength: '130',
                      }),
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF '.-]+$/,
                      ),
                      message: translate(
                        'validation_messages.must_have_only_letters',
                        { field: 'establishment.partner_full_name' },
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={gutter}>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.partner_document')}</Label>
                <Form.Item
                  name="partnerDocument"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.partner_document',
                      }),
                    },
                    { validator: isCPF },
                  ]}
                >
                  <InputMask mask={translate('masks.partner_document')}>
                    <Input />
                  </InputMask>
                </Form.Item>
              </Col>
              <Col sm={20} md={10}>
                <Label>{translate('establishment.partner_birthdate')}</Label>
                <Form.Item
                  name="partnerBirthdate"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'establishment.partner_birthdate',
                      }),
                    },
                    {
                      pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4}$/),
                      message: translate(
                        'validation_messages.must_be_in_format',
                        {
                          field: 'establishment.partner_birthdate',
                          format: 'dd-mm-aaaa',
                        },
                      ),
                    },
                    { validator: isValidDate },
                  ]}
                >
                  <InputMask mask={translate('masks.date')}>
                    <Input />
                  </InputMask>
                </Form.Item>
              </Col>
            </Row>
          </Section>
        </Form>
      </FormContainer>
    </Page>
  );
}

export default EstablishmentForm;
