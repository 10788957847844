import styled, { css } from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }

  button.ghost:hover {
    color: inherit;
    border-color: inherit;
  }
`;

export const Button = styled(AntdButton)`
  text-shadow: none;
  box-shadow: none;
`;

export const Content = styled.div`
  div.face-sheet {
    padding-bottom: 40px;
    padding-top: 55px;

    .title {
      color: #fe6a21;
      font-size: 28px;
    }

    .loading-text {
      font-weight: 600;
    }
  }

  div.face-sheet > div:last-of-type {
    display: flex;
    flex-direction: column;
    /* max-height: 300px; */
  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 360px;
    width: 100%;
    background-position: center !important;

    div.imagePlaceHolder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #ffefe9;

      img {
        width: 20%;
      }
    }
  }

  h1 {
    color: #959595;
    font-weight: 600;
    font-size: 34px;
  }

  p {
    margin-bottom: 0;
  }

  .contact-content {
    display: flex;
    align-items: center;
    color: black;

    img {
      margin-right: 20px;
      width: 17px;
      top: 1px;
      position: relative;
    }

    a {
      margin-top: 22px;
    }
  }

  .contact-content:last-of-type {
    img {
      left: -2px;
      margin: 0 17px 0 3px;
    }
  }

  .contact-content + .contact-content {
    margin-top: 15px;
  }

  a {
    margin-top: 17px;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  div.main-infos {
    border-bottom: 1px solid #d2dae9;
    padding-bottom: 60px;
    margin-bottom: 60px;

    h1 {
      font-size: 40px;
      font-weight: 600;
      color: black;
      margin-bottom: 0;
    }

    .description {
      font-size: 16px;
      color: #67687a;
    }
  }

  div.detailed-infos {
    h1 {
      font-size: 23px;
      color: #000;
    }

    h1:last-of-type {
      margin-top: 60px;
    }

    .title-content {
      display: flex;

      img {
        width: 25px;
      }
    }

    div.info-content {
      color: #67687a;

      p:first-of-type {
        color: #000;
        font-size: 19px;
        font-weight: 600;
      }
    }
  }

  div.fiscal-content {
    margin-top: 56px;
    margin-bottom: 70px;
    color: #67687a;

    h1 {
      font-size: 23px;
      color: #000;
    }

    strong {
      font-weight: 600;
      margin-right: 20px;
    }
  }

  div.map-content {
    > div {
      height: 400px;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      color: #000;
      font-size: 23px;
      display: block;
    }

    .address {
      color: #67687a;
    }
  }
`;

export const MarkerContent = styled.div`
  button {
    position: absolute;
    top: -36px;
    left: -18px;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;

    img {
      width: 25px;
    }
  }
`;

interface IMarkerTooltipProps {
  $visible: boolean;
}

export const MarkerTooltip = styled.div`
  background: white;
  width: 250px;
  border-radius: 4px;
  border: 2px solid #fe6a21;
  margin-top: -20px;
  margin-left: 27px;
  padding: 10px 20px;

  ${(props: IMarkerTooltipProps) =>
    props.$visible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  h1 {
    color: #fe6a21;
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
`;

export const Card = styled.div`
  box-shadow: 0 10px 10px rgba(211, 201, 201, 0.2);
  border-radius: 4px;
  padding: 20px 30px 20px 20px;
  min-height: 143px;

  > div {
    display: flex;
    justify-content: space-between;

    span.name {
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
    }
  }

  > div + div {
    margin-top: 25px;
  }

  .title {
    color: #fe6a21;
    font-size: 28px;
  }
`;

export const OutlinedSmallButton = styled(AntdButton)`
  text-shadow: none;
  box-shadow: none;
  border-color: #fe6a21;
  color: #fe6a21;

  height: 25px;
  line-height: 13px;
`;
