import { useState, useEffect, useCallback } from 'react';
import { Form, Col, Checkbox, DatePicker, notification, Switch } from 'antd';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';

import { create, extendPeriod, update } from 'store/ducks/campaign';

import { Store } from 'antd/lib/form/interface';
import api from 'services/fanappApi';
import Page from 'components/Page';

import { secureGoBack } from 'helpers/routeHelper';
import {
  Campaign,
  CampaignType,
  CampaignState,
  CampaignImage,
} from 'interfaces/campaign';
import { gutter } from 'constants/styles';
import { EstablishmentState } from 'interfaces/establishment';
import { isPositiveNumber, onlyNumbers } from 'services/validation';
import { translate } from 'config/i18n';

import { parseISO, isBefore } from 'date-fns';
import Dropzone from './Dropzone';
import {
  FormContainer,
  Section,
  SectionTitle,
  Input,
  Header,
  Button,
  Row,
  PriceField,
  PaymentCard,
  SwitchContainer,
} from './styles';
import CampaignTypeCard from './CampaignTypeCard';

interface INavParams {
  campaignId: string;
}

interface IPriceValues {
  value: string;
}

interface NavState {
  duplicate: boolean;
}

function CampaignForm(): JSX.Element {
  const { campaignId } = useParams<INavParams>();
  const [form] = Form.useForm();
  const { state } = useLocation<NavState>();
  const duplicate = state?.duplicate || false;
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { loading } = useSelector<ReduxStore, CampaignState>(
    campaignState => campaignState.campaign,
  );
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    establishmentState => establishmentState.establishment,
  );
  const dispatch = useDispatch();

  const [campaign, setCampaign] = useState({} as Campaign);
  const [isVoucher, setIsVoucher] = useState(false);
  const [editable, setEditable] = useState(true);
  const [status, setStatus] = useState<'published' | 'disabled' | 'limited'>(
    'limited',
  );
  const [isFixedPrice, setIsFixedPrice] = useState(true);
  const [logoPreview, setLogoPreview] = useState<string | null | undefined>('');
  const [image, setImage] = useState<CampaignImage>();
  const [imageData, setImageData] = useState<File | null>(null);
  const [categoryTypeList, setCategoryTypeList] = useState<Array<CampaignType>>(
    [],
  );
  const [preLoading, setPreLoading] = useState(true);
  const [selectedType, setSelectedType] = useState<string | null>('credit');

  useEffect(() => {
    async function getDataFromServer() {
      try {
        const promises = [];

        promises.push(api.get('customer/campaign-types'));
        if (campaignId) promises.push(api.get(`trade/campaigns/${campaignId}`));

        const responses = await Promise.all(promises);

        setCategoryTypeList(responses[0].data?.data);

        if (campaignId) {
          setLogoPreview(responses[1].data.data.file?.location || '');
          setCampaign(responses[1].data.data);
          setImage(responses[1].data.data.file);

          form.setFieldsValue({
            bonus: responses[1].data.data.bonus,
            voucher: responses[1].data.data.voucher,
            oneTime: responses[1].data.data.oneTime,
            partialRedeem: responses[1].data.data.partialRedeem,
            convenienceFee: responses[1].data.data.convenienceFee,
            limitMaxValue: responses[1].data.data.limitMaxValue / 100,
            limitMinValue: responses[1].data.data.limitMinValue / 100,
            startAt: responses[1].data.data.startAt
              ? moment(responses[1].data.data.startAt)
              : null,
            endAt: responses[1].data.data.endAt
              ? moment(responses[1].data.data.endAt)
              : null,
            validDate: responses[1].data.data.validDate
              ? moment(responses[1].data.data.validDate)
              : null,
          });

          if (
            responses[1].data.data.limitMinValue !==
            responses[1].data.data.limitMaxValue
          )
            setIsFixedPrice(false);

          if (
            responses[1].data.data.limitMaxValue ===
            responses[1].data.data.limitMinValue
          ) {
            form.setFieldsValue({
              limitMaxValue: null,
              limitMinValue: null,
              fixedPrice: responses[1].data.data.limitMaxValue / 100,
            });
          }

          setSelectedType(
            responses[0].data?.data.find(
              (x: CampaignType) =>
                x.id === responses[1].data.data.campaignTypeId,
            ).name,
          );
          setIsVoucher(responses[1].data.data.voucher);

          const startAt = parseISO(responses[1].data.data.startAt);

          if (isBefore(startAt, new Date())) {
            setEditable(false);
          }
        }
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setPreLoading(false);
      }
    }

    getDataFromServer();
  }, [campaignId, currentEstablishment, form]);

  const handleSuccess = useCallback(
    (backRoute: string) => {
      notification.success({
        message: translate('campaign.was_saved'),
        description: translate('general_messages.was_saved', {
          context: 'campaign.title',
        }),
      });
      secureGoBack(history, lastLocation, backRoute);
    },
    [history, lastLocation],
  );

  const handleExtendPeriod = () => {
    if (campaign.endAt) {
      dispatch(
        extendPeriod(campaign.endAt, campaignId, () =>
          handleSuccess('campaigns'),
        ),
      );
    } else {
      secureGoBack(history, lastLocation, '/campaigns');
    }
  };

  const handleForm = useCallback(
    (data: Store) => {
      // let fixedPrice =
      //   data.fixedPrice && typeof data.fixedPrice !== 'number'
      //     ? Number(data.fixedPrice.replace(/\./g, '').replace(',', '.'))
      //     : data.fixedPrice;

      // fixedPrice = +(fixedPrice * 100).toFixed();

      const input: Campaign = {
        id: campaignId,
        name: data.name,
        title: data.title,
        description: data.description,
        campaignTypeId: categoryTypeList.find(x => x.name === selectedType)?.id,
        status,
        // limitMinValue: fixedPrice,
        // limitMaxValue: fixedPrice,
      };

      let limitMinValue =
        data.limitMinValue && typeof data.limitMinValue !== 'number'
          ? Number(data.limitMinValue.replace(/\./g, '').replace(',', '.'))
          : data.limitMinValue;

      limitMinValue = +(limitMinValue * 100).toFixed();

      let limitMaxValue =
        data.limitMaxValue && typeof data.limitMaxValue !== 'number'
          ? Number(data.limitMaxValue.replace(/\./g, '').replace(',', '.'))
          : data.limitMaxValue;

      limitMaxValue = +(limitMaxValue * 100).toFixed();

      if (selectedType === 'credit') {
        if (!isFixedPrice && limitMinValue) input.limitMinValue = limitMinValue;
        if (!isFixedPrice && limitMaxValue) input.limitMaxValue = limitMaxValue;
      } else {
        input.voucher = true;
      }
      if (data.limitPerUser) input.limitPerUser = data.limitPerUser;
      if (isVoucher) {
        input.voucher = isVoucher;
        input.partialRedeem = data.partialRedeem || false;
        input.oneTime = data.oneTime || false;
        input.bonus = data.bonus;
      }

      if (data.startAt) input.startAt = data.startAt.toString();
      if (data.endAt) input.endAt = data.endAt.toString();

      if (
        (selectedType === 'credit' && isVoucher && data.validDate) ||
        (selectedType !== 'credit' && data.validDate)
      )
        input.validDate = data.validDate.toString();

      if (!campaignId || duplicate) {
        dispatch(
          create(currentEstablishment?.id as string, input, imageData, () =>
            handleSuccess('/campaigns'),
          ),
        );
      } else if (editable) {
        dispatch(update(input, imageData, () => handleSuccess('/campaigns')));
      }
    },
    [
      dispatch,
      campaignId,
      currentEstablishment,
      imageData,
      categoryTypeList,
      selectedType,
      isFixedPrice,
      isVoucher,
      status,
      editable,
      duplicate,
      handleSuccess,
    ],
  );

  const handleImageDrop = useCallback(acceptedFiles => {
    const imgData = acceptedFiles[0];
    setImage(imgData);
    const reader = new FileReader();
    reader.onload = () => {
      setLogoPreview(reader?.result?.toString());
      setImageData(imgData);
    };
    reader.readAsDataURL(imgData);
  }, []);

  useEffect(() => {
    if (!isVoucher) {
      form.setFieldsValue({
        convenienceFee: null,
        bonus: null,
        partialRedeem: null,
        oneTime: null,
        validDate: null,
        startAt: null,
        endAt: null,
      });
    }
  }, [isVoucher, form]);

  useEffect(() => {
    if (isFixedPrice) {
      form.setFieldsValue({
        limitMinValue: null,
        limitMaxValue: null,
      });
    } else {
      form.setFieldsValue({
        fixedPrice: null,
      });
    }
  }, [isFixedPrice, form]);

  const getDefaultDate = () => {
    const validDate = form.getFieldValue('validDate');
    if (validDate) return moment(form.getFieldValue('validDate'), 'dd/MM/yyyy');
    return undefined;
  };

  return (
    <Page
      displayDrawer
      title={translate('campaign.plural_title', {
        mode: campaignId
          ? translate('campaign.editing')
          : translate('campaign.register'),
      })}
      showBackButton
      loading={preLoading}
      footer={
        <Header>
          <Button
            onClick={() => {
              secureGoBack(history, lastLocation, '/campaigns');
            }}
            htmlType="button"
            type="link"
            className="cancel-button"
          >
            {editable ? translate('general.cancel') : translate('general.back')}
          </Button>

          {(!campaignId || duplicate) && (
            <Button
              disabled={loading}
              onClick={() => {
                setStatus('published');
                form.submit();
              }}
              htmlType="submit"
              type="link"
            >
              {loading
                ? translate('general.publishing')
                : translate('general.publish')}
            </Button>
          )}

          {(!campaignId ||
            duplicate ||
            editable ||
            (campaign.endAt &&
              !isBefore(parseISO(campaign.endAt!), new Date())) ||
            !campaign.endAt) && (
            <Button
              disabled={loading}
              onClick={() => {
                form.validateFields();

                if (campaignId && !editable && !duplicate) handleExtendPeriod();
                else form.submit();
              }}
              htmlType="submit"
              type="primary"
            >
              {loading
                ? translate('general.saving')
                : translate('general.save')}
            </Button>
          )}
        </Header>
      }
    >
      <FormContainer>
        <Form initialValues={campaign} form={form} onFinish={handleForm}>
          <Section>
            <SectionTitle>Tipos de campanha</SectionTitle>

            <Row gutter={gutter} className="mb-section">
              <Col sm={24} md={8}>
                <CampaignTypeCard
                  cardName="credit"
                  selectedType={selectedType}
                  setSelectedType={
                    !campaignId || duplicate || editable
                      ? setSelectedType
                      : null
                  }
                />
              </Col>
              <Col sm={24} md={8}>
                <CampaignTypeCard
                  cardName="product"
                  selectedType={selectedType}
                  setSelectedType={
                    !campaignId || duplicate || editable
                      ? setSelectedType
                      : null
                  }
                />
              </Col>
              <Col sm={24} md={8}>
                <CampaignTypeCard
                  cardName="ticket"
                  selectedType={selectedType}
                  setSelectedType={
                    !campaignId || duplicate || editable
                      ? setSelectedType
                      : null
                  }
                />
              </Col>
            </Row>

            <SectionTitle>Identificação da campanha</SectionTitle>

            <Row gutter={gutter} className="mb-2">
              {selectedType !== 'credit' && (
                <Col md={24} lg={8} className="dropzone-column">
                  <Dropzone
                    alt={translate('campaign.campaign_image')}
                    onDropAccepted={handleImageDrop}
                    onClean={(e: any) => {
                      e.stopPropagation();
                      setLogoPreview('');
                    }}
                    logoPreview={logoPreview}
                    image={image}
                  />
                </Col>
              )}
              <Col sm={16}>
                <label>Título</label>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'campaign.title_field',
                      }),
                    },
                  ]}
                >
                  <Input disabled={!(!campaignId || duplicate || editable)} />
                </Form.Item>

                <label className="optional">Descrição</label>
                <Form.Item name="description">
                  <Input.TextArea
                    disabled={!(!campaignId || duplicate || editable)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <SectionTitle>Critérios de pagamento</SectionTitle>
            {selectedType === 'credit' && (
              <Row
                gutter={gutter}
                className="mb-section"
                visible={selectedType === 'credit'}
              >
                <Col sm={24} lg={8}>
                  <PaymentCard
                    className={isFixedPrice ? '' : 'unselected'}
                    onClick={() => {
                      if (!campaignId || duplicate || editable)
                        setIsFixedPrice(true);
                    }}
                  >
                    <Checkbox
                      checked={isFixedPrice}
                      onChange={({ target }) => {
                        setIsFixedPrice(target.checked);
                      }}
                    />
                    <div>
                      <span className="title">Valor Fixo</span>
                      <p style={{ marginBottom: 55 }}>
                        Adicione um valor específico para a transação dos
                        usuários desta campanha.
                      </p>
                      <Form.Item
                        name="fixedPrice"
                        rules={[
                          { validator: isPositiveNumber },
                          () => ({
                            validator(_, value) {
                              if (!value && isFixedPrice) {
                                return Promise.reject(
                                  new Error(
                                    translate(
                                      'validation_messages.required_field',
                                      {
                                        field: 'campaign.fixedPrice',
                                      },
                                    ),
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <PriceField
                          disabled={
                            !(!campaignId || duplicate || editable) ||
                            !isFixedPrice
                          }
                          placeholder="Valor da transação"
                          thousandSeparator="."
                          decimalSeparator=","
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={false}
                          className="ant-input clean-button"
                          onValueChange={({ value }: IPriceValues) => {
                            form.setFieldsValue({
                              fixedPrice: Number(value),
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                  </PaymentCard>
                </Col>
                <Col sm={24} lg={8}>
                  <PaymentCard
                    className={isFixedPrice ? 'unselected' : ''}
                    onClick={() => {
                      if (!campaignId || duplicate || editable)
                        setIsFixedPrice(false);
                    }}
                  >
                    <Checkbox
                      checked={!isFixedPrice}
                      onChange={({ target }) => {
                        setIsFixedPrice(target.checked);
                      }}
                    />
                    <div>
                      <span className="title">Limite de transação</span>
                      <p>
                        Estipule um intervalo de valores para esta campanha,
                        assim haverá um controle para as transações.
                      </p>
                      <div className="single-line">
                        <Form.Item
                          name="limitMinValue"
                          rules={[{ validator: isPositiveNumber }]}
                        >
                          <PriceField
                            placeholder={translate('campaign.limitMinValue')}
                            disabled={
                              !(!campaignId || duplicate || editable) ||
                              isFixedPrice
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={false}
                            className="ant-input clean-button"
                            onValueChange={({ value }: IPriceValues) => {
                              form.setFieldsValue({
                                limitMinValue: Number(value),
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="limitMaxValue"
                          rules={[
                            { validator: isPositiveNumber },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  value &&
                                  getFieldValue('limitMinValue') &&
                                  Number(
                                    value.replace(',', '.').replace('.', ''),
                                  ) <
                                    Number(
                                      getFieldValue('limitMinValue')
                                        .toString()
                                        .replace(',', '.')
                                        .replace('.', ''),
                                    )
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      translate(
                                        'validation_messages.number_must_be_grater_than_field',
                                        {
                                          grater: 'campaign.limitMaxValue',
                                          smaller: 'campaign.limitMinValue',
                                        },
                                      ),
                                    ),
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <PriceField
                            placeholder={translate('campaign.limitMaxValue')}
                            disabled={
                              !(!campaignId || duplicate || editable) ||
                              isFixedPrice
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={false}
                            className="ant-input clean-button"
                            onValueChange={({ value }: IPriceValues) => {
                              form.setFieldsValue({
                                limitMaxValue: Number(value),
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </PaymentCard>
                </Col>
              </Row>
            )}

            {selectedType !== 'credit' && (
              <Row
                gutter={gutter}
                className="mb-section"
                visible={selectedType !== 'credit'}
              >
                <Col sm={24} md={12}>
                  <label>Valor do produto</label>
                  <Form.Item
                    name="fixedPrice"
                    rules={[{ validator: isPositiveNumber }]}
                  >
                    <PriceField
                      disabled={!(!campaignId || duplicate || editable)}
                      placeholder="Quanto?"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={false}
                      className="ant-input clean-button"
                      onValueChange={({ value }: IPriceValues) => {
                        form.setFieldsValue({
                          fixedPrice: Number(value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <SectionTitle>
              Período de campanha <span>(opcional)</span>
            </SectionTitle>
            <Row gutter={gutter} className="mb-section">
              <Col sm={24} md={6}>
                <label>Início da campanha</label>
                <Form.Item
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value < moment().startOf('day'))
                          return Promise.reject(
                            new Error(
                              translate(
                                'validation_messages.date_must_be_grater_than_today',
                                {
                                  grater: 'campaign.startAt',
                                },
                              ),
                            ),
                          );
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  name="startAt"
                >
                  <DatePicker
                    disabled={!(!campaignId || duplicate || editable)}
                    format="DD/MM/YYYY"
                    placeholder="Quando?"
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={6}>
                <label>Fim da campanha</label>
                <Form.Item
                  name="endAt"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && getFieldValue('startAt')) {
                          if (value < getFieldValue('startAt'))
                            return Promise.reject(
                              new Error(
                                translate(
                                  'validation_messages.date_must_be_grater_than_field',
                                  {
                                    grater: 'campaign.endAt',
                                    smaller: 'campaign.startAt',
                                  },
                                ),
                              ),
                            );
                        }
                        return Promise.resolve();
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && getFieldValue('validDate')) {
                          if (value > getFieldValue('validDate'))
                            return Promise.reject(
                              new Error(
                                translate(
                                  'validation_messages.date_must_be_smaller_than_field',
                                  {
                                    smaller: 'campaign.endAt',
                                    grater: 'campaign.validDate',
                                  },
                                ),
                              ),
                            );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    disabled={
                      campaign.endAt && !duplicate
                        ? isBefore(parseISO(campaign.endAt!), new Date())
                        : false
                    }
                    defaultValue={moment(
                      form.getFieldValue('endAt'),
                      'dd/MM/yyyy',
                    )}
                    format="DD/MM/YYYY"
                    placeholder="Quando?"
                  />
                </Form.Item>
              </Col>
            </Row>

            <SectionTitle>
              Ativação de regras <span>(opcional)</span>
            </SectionTitle>

            {selectedType === 'credit' && (
              <Row gutter={gutter} visible={selectedType === 'credit'}>
                <Col sm={24} md={6}>
                  <Form.Item valuePropName="checked" name="voucher">
                    <SwitchContainer>
                      <Switch
                        checked={isVoucher}
                        disabled={!(!campaignId || duplicate || editable)}
                        onChange={() => {
                          setIsVoucher(!isVoucher);
                        }}
                      />
                      <span>Gerar voucher</span>
                    </SwitchContainer>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={gutter}>
              <Col sm={24} md={6}>
                <label className="optional">Expiração</label>
                <Form.Item name="validDate">
                  <DatePicker
                    disabled={
                      (!isVoucher && selectedType === 'credit') ||
                      !(!campaignId || duplicate || editable)
                    }
                    defaultValue={getDefaultDate()}
                    format="DD/MM/YYYY"
                    placeholder="Data de validade"
                  />
                </Form.Item>
              </Col>
              <Col
                sm={24}
                md={6}
                className={selectedType !== 'credit' ? 'invisible' : ''}
              >
                <label>Bônus</label>
                <Form.Item
                  name="bonus"
                  rules={[
                    { validator: isPositiveNumber },
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(_rule, value, callback, 'Bônus'),
                    },
                    () => ({
                      validator(_, value) {
                        if (!value && isVoucher && selectedType === 'credit') {
                          return Promise.reject(
                            new Error(
                              translate('validation_messages.required_field', {
                                field: 'campaign.bonus',
                              }),
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    disabled={
                      !(!campaignId || duplicate || editable) || !isVoucher
                    }
                    maxLength={3}
                    placeholder={translate('campaign.bonus')}
                  />
                </Form.Item>
              </Col>
            </Row>

            {selectedType === 'credit' && (
              <Row gutter={gutter} visible={selectedType === 'credit'}>
                <Col sm={24} md={6}>
                  <Form.Item valuePropName="checked" name="partialRedeem">
                    <SwitchContainer>
                      <Switch
                        disabled={
                          !(!campaignId || duplicate || editable) || !isVoucher
                        }
                        defaultChecked={form.getFieldValue('partialRedeem')}
                        onChange={() => {
                          form.setFieldsValue({
                            partialRedeem: !form.getFieldValue('partialRedeem'),
                          });
                        }}
                      />
                      <span>Resgate parcial</span>
                    </SwitchContainer>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={gutter}>
              <Col sm={24} md={6}>
                <label>Limite por cliente</label>
                <Form.Item
                  name="limitPerUser"
                  rules={[
                    { validator: isPositiveNumber },
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(
                          _rule,
                          value,
                          callback,
                          'Limite por cliente',
                        ),
                    },
                  ]}
                >
                  <Input
                    placeholder="Limite por cliente"
                    disabled={!(!campaignId || duplicate || editable)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col sm={24} md={6}>
              <Form.Item valuePropName="checked" name="oneTime">
                <SwitchContainer>
                  <Switch
                    disabled={!(!campaignId || duplicate || editable)}
                    defaultChecked={form.getFieldValue('oneTime')}
                    onChange={() => {
                      form.setFieldsValue({
                        oneTime: !form.getFieldValue('oneTime'),
                      });
                    }}
                  />
                  <span>Transação única</span>
                </SwitchContainer>
              </Form.Item>
            </Col>
          </Section>
        </Form>
      </FormContainer>
    </Page>
  );
}

export default CampaignForm;
